<template>
  <b-navbar type="is-primary" class="has-text-left" shadow>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }" data-v-step="0">
        <img src="@/assets/harpia_logo.png" alt="ALTAVE | Harpia" />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item
        tag="router-link"
        to="/"
        v-if="
          !isOffshoreFlag &&
          !isMordorFlag &&
          !isNewMordorFlag &&
          GetCurrentUser.activeFlag.toLowerCase() !== 'aker' &&
          GetCurrentUser.activeFlag !== 'innovation'
        "
      >
        <span
          class="is-family-sans-serif"
          :class="{
            'has-text-underlined': GetCurrentRouteName === 'Transmissão',
          }"
          >{{ $t("navbar.transmission") }}</span
        >
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        to="/warning"
        v-if="
          GetCurrentUser.permissions.alertsview &&
          !isMordorFlag &&
          !isNewMordorFlag
        "
      >
        <span
          class="is-family-sans-serif"
          :class="{ 'has-text-underlined': GetCurrentRouteName === 'Alertas' }"
          >{{ $t("navbar.warnings") }}</span
        >
      </b-navbar-item>

      <b-navbar-item
        tag="router-link"
        to="/newMordor"
        v-if="
          isNewMordorFlag &&
          (GetCurrentUser.role != 'client' ||
            GetCurrentUser.company.toLowerCase() == 'altave')
        "
      >
        <span
          class="is-family-sans-serif"
          :class="{ 'has-text-underlined': GetCurrentRouteName === 'Mordor' }"
          >Mordor</span
        >
      </b-navbar-item>

      <b-navbar-item
        tag="router-link"
        to="/management"
        v-if="
          GetCurrentUser.role === 'admin' ||
          GetCurrentUser.role === 'operator' ||
          GetCurrentUser.role === 'supervisor'
        "
      >
        <span
          class="is-family-sans-serif"
          :class="{
            'has-text-underlined': GetCurrentRouteName === 'Administração',
          }"
          >{{ $t("navbar.administration") }}</span
        >
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        to="/sauron"
        v-if="GetCurrentUser.permissions.assistedsauron && !isMordorFlag"
      >
        <span
          class="is-family-sans-serif"
          :class="{
            'has-text-underlined': GetCurrentRouteName === 'Sauron Assistido',
          }"
          >{{ $t("navbar.sauron") }}</span
        >
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        to="/sauron-auxiliary"
        v-if="
          GetCurrentUser.role.toLowerCase() == 'operator' ||
          GetCurrentUser.permissions.sauronassistant
        "
      >
        <span
          class="is-family-sans-serif"
          :class="{
            'has-text-underlined': GetCurrentRouteName === 'Sauron Auxiliar',
          }"
          >SAURON auxiliar</span
        >
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        to="/mordor"
        v-if="GetCurrentUser.permissions.mordorondemand && isMordorFlag"
      >
        <span
          class="is-family-sans-serif"
          :class="{
            'has-text-underlined': GetCurrentRouteName === 'Mordor sob Demanda',
          }"
          >{{ $t("navbar.mordor") }}</span
        >
      </b-navbar-item>

      <b-navbar-item v-if="GetCurrentUser.flag.length == 1">
        <span class="is-family-sans-serif">{{
          GetCurrentUser.activeFlagAlias.toUpperCase()
        }}</span>
      </b-navbar-item>

      <b-navbar-dropdown
        v-else
        :label="
          $t('navbar.operation') +
          ' (' +
          GetCurrentUser.activeFlagAlias.toUpperCase() +
          ')'
        "
        data-v-step="2"
        boxed
        collapsible
      >
        <b-navbar-item
          v-for="(flag, index) in OrderedFlag"
          :key="index"
          @click="SwitchCurrentUserActiveFlag(flag)"
        >
          <b-icon
            class="media-left is-hidden-mobile"
            icon="rocket"
            size="is-small"
          ></b-icon>
          <h3 v-if="flag.alias">{{ flag.alias.toUpperCase() }}</h3>
        </b-navbar-item>
      </b-navbar-dropdown>

      <b-navbar-dropdown
        v-if="GetCurrentUser.activeFlagAlias.toLowerCase() == 'transpetro'"
        :label="GetCurrentCamera.toUpperCase()"
        data-v-step="2"
        boxed
        collapsible
      >
        <b-navbar-item
          v-for="(cam, index) in GetCameras"
          :key="index"
          @click="SwitchCurrentUserActiveCam(cam)"
        >
          <b-icon
            class="media-left is-hidden-mobile"
            icon="video"
            size="is-small"
          ></b-icon>
          <h3>{{ cam.toUpperCase() }}</h3>
        </b-navbar-item>
      </b-navbar-dropdown>

      <!-- <b-navbar-dropdown label="Cameras">
                <b-navbar-item> teste</b-navbar-item>
            </b-navbar-dropdown> -->
    </template>
    <template slot="end">
      <b-navbar-dropdown
        :label="this.$i18n.t('navbar.language')"
        boxed
        collapsible
      >
        <b-navbar-item @click="SetHarpiaLanguage('pt-BR')">
          <country-flag country="br" />
          Português
        </b-navbar-item>
        <b-navbar-item @click="SetHarpiaLanguage('en-US')">
          <country-flag country="us" />
          English
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-item class="has-non-hoverable-text" v-if="GetCurrentUser.name">
        <span class="has-text-weight-semibold is-family-sans-serif">{{
          GetCurrentUser.name
        }}</span>
      </b-navbar-item>
      <b-navbar-item class="has-non-hoverable-text is-hidden-desktop">
        <span class="is-size-8 is-family-sans-serif"
          >v{{ GetSystemVersion }}</span
        >
      </b-navbar-item>
      <b-navbar-item>
        <b-button class="btn" @click="LogUserOut" outlined
          ><span class="is-family-sans-serif btn">{{
            $t("navbar.logout")
          }}</span
          ><b-icon type="is-link" pack="mdi" icon="logout"
        /></b-button>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>
<style lang="scss" scoped>
.btn {
  border-radius: 6px;
  font-weight: 700;
  font-size: 1.8vh;
  vertical-align: text-bottom;
  color: #005eb1;
}
.navbar.has-shadow {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
}
.navbar-item.has-non-hoverable-text:hover {
  background-color: #0f4c81 !important;
  cursor: text !important;
}
.is-size-8 {
  font-size: 0.5rem;
}
.has-text-underlined {
  border-bottom: 2px solid white;
}
</style>
<script>
import CountryFlag from "vue-country-flag";
export default {
  name: "TheNavbar",
  components: {
    CountryFlag,
  },
  props: {
    cams: Array,
  },
  data() {
    return {
      ShowNavbarItems: false,
      CurrentUser: null,
      cameras: [],
    };
  },
  computed: {
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    GetSystemVersion() {
      return this.$store.getters.GetSystemVersion;
    },
    GetCurrentRouteName() {
      return this.$route.name;
    },
    GetCurrentCamera() {
      return this.$store.getters["database/GetCurrentCamera"];
    },
    GetCameras() {
      return this.$store.getters["database/GetCameras"];
    },
    OrderedFlag() {
      const flag = this.GetCurrentUser.flag;
      const ordered_flag = flag.sort(function (a, b) {
        if (a.alias.toLowerCase() < b.alias.toLowerCase()) {
          return -1;
        } else {
          return true;
        }
      });
      return ordered_flag;
    },
    isOffshoreFlag() {
      const offshoreFlags = [
        "baru_iporanga",
        "baru_ubatuba",
        "bram_ambassador",
        "bram_admiral",
        "bram_reedbuck",
        "bram_sailor",
        "carina",
        "dof_ipanema",
        "jupiter",
        "m_jmendes",
        "m_vale",
        "oceanpact",
        "oceanpact_sao_paulo",
        "oceanpact_sao_pedro",
        "petrogroup_tembungo_a",
        "ocyan",
        "ocyan_norbe_ix",
        "seadrill_carina",
        "seadrill_jupiter",
        "seadrill_tellus",
        "tellus",
        "valaris",
        "valaris_ds04",
      ];
      return offshoreFlags.includes(
        this.GetCurrentUser.activeFlag.toLowerCase()
      );
    },
    isMordorFlag() {
      const mordorFlags = [
        "mordor-01-trovao",
        "mordor-01-torre",
        "mordor-02-trovao",
        "mordor-03-trovao",
        "mordor-04-trovao",
        "mrd-01-vale",
        "mrd-02-vale",
        "mrd-03-vale",
      ];
      return mordorFlags.includes(this.GetCurrentUser.activeFlag.toLowerCase());
    },
    isNewMordorFlag() {
      const newMordorFlags = [
        "transpetro",
        "sabesp",
        "sabesp_cacapava",
        "sabesp_guararema",
        "puerta_de_oro",
      ];
      return newMordorFlags.includes(
        this.GetCurrentUser.activeFlag.toLowerCase()
      );
    },
  },
  methods: {
    LogUserOut() {
      this.$store.dispatch("user/Logout", this.GetCurrentUser);
    },
    SetHarpiaLanguage(language) {
      this.$i18n.locale = language;
    },
    SwitchCurrentUserActiveFlag(flag) {
      this.$store
        .dispatch("database/GetCamerasFromDatabase", flag.name.toLowerCase())
        .then(() => {
          this.$store.dispatch("user/SwitchCurrentUserActiveFlag", flag);
        });
    },
    SwitchCurrentUserActiveCam(cam) {
      this.$store.dispatch("database/SwitchCurrentUserActiveCam", cam);
    },
    GetCamerasFromDatabase() {
      this.$store.dispatch(
        "database/GetCamerasFromDatabase",
        this.GetCurrentUser.activeFlag.toLowerCase()
      );
    },
  },
  created() {
    this.GetCamerasFromDatabase();
  },
};
</script>
